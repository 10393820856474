<template>
	<div class="">
		<el-dialog :visible.sync="videodialogVisible" width="580px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()"><i class="el-icon-error"></i></span>
				<div class="videobox">
					<!-- <div class="prism-player" id="J_prismPlayer" style="margin-left: auto; margin-right: auto;"></div> -->
					<video-player class="video-player vjs-custom-skin" v-if="playshow" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
					<div class="message">
						<div class="person-info">
							<div class="p-news">
								<div class="pn-name">
									<div class="namemain font22">
										<span class="font22">{{config.displayName}}</span>
										<!-- <i class="el-icon-star-off"></i> -->
										<!-- <i class="el-icon-star-on"></i> -->
									</div>
									<div class="score font14"><span>{{config.score}}</span></div>
								</div>
								<div class="beizhu font14">
									{{config.expYear}}年 | {{config.educationList && config.educationList.length ? config.educationList[config.educationList.length-1].educationType.itemText : '/'}} | {{new Date().getYear() - new Date(config.birthday).getYear()}}岁 | {{config.jobLevelNames}}
								</div>
							</div>
							<div class="p-dec font14">
								<!-- 1.具备Android手机平台开发经验；了解并能熟练使用Android开发工具以及其他开发工具； 2.具有良好的编程技巧和文档编写能力； -->
								{{config.cvDescription}}
							</div>
						</div>
					</div>
					
					
					
					
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from '../../common/bus.js';
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
	components: {
		videoPlayer
	},
	data() {
		return {
			playshow:true,
			videodialogVisible: false,
			config:{},
			playerOptions: {
				playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
				autoplay: false, //如果true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 导致视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [
					{
						src: '', // 路径
						type: 'video/mp4' // 类型
					},
					// {
					// 	src: '//path/to/video.webm',
					// 	type: 'video/webm'
					// }
				],
				poster: '', //你的封面地址
				// width: document.documentElement.clientWidth,
				notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true,
					durationDisplay: true,
					remainingTimeDisplay: false,
					fullscreenToggle: true //全屏按钮
				}
			}
		};
	},
	created() {
		
	},
	mounted() {
		Bus.$on('videodstuts2', data => {
			this.videodialogVisible = data.show;
			this.playshow=data.show;
			this.getdata(data)
			this.config=data.data
		});
	},
	methods: {
		closebox() {
			this.videodialogVisible = false;
			Bus.$emit('videodstuts2', {show:false,data:'data'});
			this.playshow=false;
		},
		getdata(data){
			this.playerOptions.sources[0].src="https://freemen.work/user/file/view/"+data.data.resUrl
		},
		
		
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
.video-js .vjs-big-play-button{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
<style lang="less" scoped>

.closeicon {
	position: absolute;
	top: -40px;
	right: 0;
	font-size: 26px;
	color: #f3f3f3;
}
.details {
	.wb-tit {
		padding: 17px 0;
		text-align: center;
		background-color: #f3f3f3;
		color: #000000;
		font-weight: 600;
	}
}

.message{
	padding: 20px 30px;
	.person-info {
		// display: flex;
		// align-items: center;
		
		
		.p-news {
			display: flex;
			flex: 1;
			flex-direction: column;
			line-height: 1.6;
			border-bottom: 1px solid #dddddd;
			padding-bottom: 20px;
			.pn-name {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.namemain {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					color: #111111;
					.el-icon-star-off,.el-icon-star-on{
						color: #FFA800;
						margin-left: 14px;
					}
				}
				.score {
					background-color: #00bcff;
					border-radius: 8px 8px 8px 0;
					color: #ffffff;
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.beizhu {
				color: #666666;
			}
		}
		.p-dec{
			margin-top: 20px;
			color: #666666;
			line-height: 1.5;
		}
	}
}




</style>
