export let city = [
	{name:'北京市',py:'B'},
	{name:'天津市',py:'T'},
	{name:'石家庄市',py:'S'},
	{name:'秦皇岛市',py:'Q'},
	{name:'邢台市',py:'X'},
	{name:'保定市',py:'B'},
	{name:'张家口市',py:'Z'},
	{name:'承德市',py:'C'},
	{name:'沧州市',py:'C'},
	{name:'廊坊市',py:'L'},
	{name:'衡水市',py:'H'},
	{name:'太原市',py:'T'},
	{name:'大同市',py:'D'},
	{name:'阳泉市',py:'Y'},
	{name:'长治市',py:'C'},
	{name:'晋城市',py:'J'},
	{name:'朔州市',py:'S'},
	{name:'晋中市',py:'J'},
	{name:'运城市',py:'Y'},
	{name:'忻州市',py:'X'},
	{name:'临汾市',py:'L'},
	{name:'吕梁市',py:'L'},
	{name:'呼和浩特市',py:'H'},
	{name:'包头市',py:'B'},
	{name:'乌海市',py:'W'},
	{name:'赤峰市',py:'C'},
	{name:'通辽市',py:'T'},
	{name:'鄂尔多斯市',py:'E'},
	{name:'呼伦贝尔市',py:'H'},
	{name:'巴彦淖尔市',py:'B'},
	{name:'乌兰察布市',py:'W'},
	{name:'兴安盟',py:'X'},
	{name:'锡林郭勒盟',py:'X'},
	{name:'阿拉善盟',py:'A'},
	{name:'沈阳市',py:'L'},
	{name:'大连市',py:'D'},
	{name:'鞍山市',py:'A'},
	{name:'抚顺市',py:'F'},
	{name:'本溪市',py:'B'},
	{name:'丹东市',py:'D'},
	{name:'锦州市',py:'J'},
	{name:'营口市',py:'Y'},
	{name:'阜新市',py:'F'},
	{name:'辽阳市',py:'L'},
	{name:'盘锦市',py:'P'},
	{name:'铁岭市',py:'T'},
	{name:'朝阳市',py:'C'},
	{name:'葫芦岛市',py:'H'},
	{name:'长春市',py:'C'},
	{name:'吉林市',py:'J'},
	{name:'四平市',py:'S'},
	{name:'辽源市',py:'L'},
	{name:'通化市',py:'T'},
	{name:'白山市',py:'B'},
	{name:'松原市',py:'S'},
	{name:'白城市',py:'B'},
	{name:'延边朝鲜族自治州',py:'Y'},
	{name:'哈尔滨市',py:'H'},
	{name:'齐齐哈尔市',py:'Q'},
	{name:'鸡西市',py:'J'},
	{name:'鹤岗市',py:'H'},
	{name:'双鸭山市',py:'S'},
	{name:'大庆市',py:'D'},
	{name:'伊春市',py:'Y'},
	{name:'佳木斯市',py:'J'},
	{name:'七台河市',py:'Q'},
	{name:'牡丹江市',py:'M'},
	{name:'黑河市',py:'H'},
	{name:'绥化市',py:'S'},
	{name:'大兴安岭地区',py:'D'},
	{name:'上海市',py:'S'},
	{name:'南京市',py:'N'},
	{name:'无锡市',py:'W'},
	{name:'徐州市',py:'H'},
	{name:'常州市',py:'C'},
	{name:'苏州市',py:'S'},
	{name:'南通市',py:'N'},
	{name:'连云港市',py:'L'},
	{name:'淮安市',py:'H'},
	{name:'盐城市',py:'Y'},
	{name:'扬州市',py:'Y'},
	{name:'镇江市',py:'Z'},
	{name:'泰州市',py:'T'},
	{name:'宿迁市',py:'S'},
	{name:'杭州市',py:'H'},
	{name:'宁波市',py:'N'},
	{name:'温州市',py:'W'},
	{name:'嘉兴市',py:'J'},
	{name:'湖州市',py:'H'},
	{name:'绍兴市',py:'S'},
	{name:'金华市',py:'J'},
	{name:'衢州市',py:'Q'},
	{name:'舟山市',py:'Z'},
	{name:'台州市',py:'T'},
	{name:'丽水市',py:'L'},
	{name:'合肥市',py:'H'},
	{name:'芜湖市',py:'W'},
	{name:'蚌埠市',py:'B'},
	{name:'淮南市',py:'H'},
	{name:'马鞍山市',py:'M'},
	{name:'淮北市',py:'H'},
	{name:'铜陵市',py:'T'},
	{name:'安庆市',py:'A'},
	{name:'黄山市',py:'H'},
	{name:'滁州市',py:'C'},
	{name:'阜阳市',py:'F'},
	{name:'宿州市',py:'S'},
	{name:'六安市',py:'L'},
	{name:'亳州市',py:'H'},
	{name:'池州市',py:'C'},
	{name:'宣城市',py:'X'},
	{name:'福州市',py:'F'},
	{name:'厦门市',py:'X'},
	{name:'莆田市',py:'P'},
	{name:'三明市',py:'S'},
	{name:'泉州市',py:'Q'},
	{name:'漳州市',py:'Z'},
	{name:'南平市',py:'N'},
	{name:'龙岩市',py:'L'},
	{name:'宁德市',py:'N'},
	{name:'南昌市',py:'N'},
	{name:'景德镇市',py:'J'},
	{name:'萍乡市',py:'P'},
	{name:'九江市',py:'J'},
	{name:'新余市',py:'X'},
	{name:'鹰潭市',py:'Y'},
	{name:'赣州市',py:'G'},
	{name:'吉安市',py:'J'},
	{name:'宜春市',py:'Y'},
	{name:'抚州市',py:'F'},
	{name:'上饶市',py:'S'},
	{name:'济南市',py:'J'},
	{name:'青岛市',py:'Q'},
	{name:'淄博市',py:'Z'},
	{name:'枣庄市',py:'Z'},
	{name:'东营市',py:'D'},
	{name:'烟台市',py:'Y'},
	{name:'潍坊市',py:'W'},
	{name:'济宁市',py:'J'},
	{name:'泰安市',py:'T'},
	{name:'威海市',py:'W'},
	{name:'日照市',py:'R'},
	{name:'莱芜市',py:'C'},
	{name:'临沂市',py:'L'},
	{name:'德州市',py:'D'},
	{name:'聊城市',py:'L'},
	{name:'滨州市',py:'B'},
	{name:'菏泽市',py:'H'},
	{name:'郑州市',py:'Z'},
	{name:'开封市',py:'K'},
	{name:'洛阳市',py:'L'},
	{name:'平顶山市',py:'P'},
	{name:'安阳市',py:'A'},
	{name:'鹤壁市',py:'H'},
	{name:'新乡市',py:'X'},
	{name:'焦作市',py:'J'},
	{name:'濮阳市',py:'P'},
	{name:'许昌市',py:'X'},
	{name:'漯河市',py:'L'},
	{name:'三门峡市',py:'S'},
	{name:'南阳市',py:'N'},
	{name:'商丘市',py:'S'},
	{name:'信阳市',py:'X'},
	{name:'周口市',py:'Z'},
	{name:'驻马店市',py:'Z'},
	{name:'济源市',py:'J'},
	{name:'武汉市',py:'W'},
	{name:'黄石市',py:'H'},
	{name:'十堰市',py:'S'},
	{name:'宜昌市',py:'Y'},
	{name:'襄阳市',py:'X'},
	{name:'鄂州市',py:'E'},
	{name:'荆门市',py:'J'},
	{name:'孝感市',py:'X'},
	{name:'荆州市',py:'J'},
	{name:'黄冈市',py:'H'},
	{name:'咸宁市',py:'X'},
	{name:'随州市',py:'S'},
	{name:'恩施土家族苗族自治州',py:'N'},
	{name:'仙桃市',py:'X'},
	{name:'潜江市',py:'Q'},
	{name:'天门市',py:'T'},
	{name:'神农架林区',py:'S'},
	{name:'长沙市',py:'C'},
	{name:'株洲市',py:'Z'},
	{name:'湘潭市',py:'X'},
	{name:'衡阳市',py:'H'},
	{name:'邵阳市',py:'S'},
	{name:'岳阳市',py:'Y'},
	{name:'常德市',py:'C'},
	{name:'张家界市',py:'Z'},
	{name:'益阳市',py:'Y'},
	{name:'郴州市',py:'C'},
	{name:'永州市',py:'Y'},
	{name:'怀化市',py:'H'},
	{name:'娄底市',py:'L'},
	{name:'湘西土家族苗族自治州',py:'X'},
	{name:'广州市',py:'G'},
	{name:'韶关市',py:'S'},
	{name:'深圳市',py:'S'},
	{name:'珠海市',py:'Z'},
	{name:'汕头市',py:'S'},
	{name:'佛山市',py:'F'},
	{name:'江门市',py:'J'},
	{name:'湛江市',py:'Z'},
	{name:'茂名市',py:'M'},
	{name:'肇庆市',py:'Z'},
	{name:'惠州市',py:'H'},
	{name:'梅州市',py:'M'},
	{name:'汕尾市',py:'S'},
	{name:'河源市',py:'H'},
	{name:'阳江市',py:'Y'},
	{name:'清远市',py:'Q'},
	{name:'东莞市',py:'D'},
	{name:'中山市',py:'Z'},
	{name:'东沙群岛',py:'D'},
	{name:'潮州市',py:'C'},
	{name:'揭阳市',py:'J'},
	{name:'云浮市',py:'Y'},
	{name:'南宁市',py:'N'},
	{name:'柳州市',py:'L'},
	{name:'桂林市',py:'G'},
	{name:'梧州市',py:'W'},
	{name:'北海市',py:'B'},
	{name:'防城港市',py:'F'},
	{name:'钦州市',py:'Q'},
	{name:'贵港市',py:'G'},
	{name:'玉林市',py:'Y'},
	{name:'百色市',py:'B'},
	{name:'贺州市',py:'H'},
	{name:'河池市',py:'H'},
	{name:'来宾市',py:'L'},
	{name:'崇左市',py:'C'},
	{name:'海口市',py:'H'},
	{name:'三亚市',py:'S'},
	{name:'三沙市',py:'S'},
	{name:'儋州市',py:'D'},
	{name:'五指山市',py:'W'},
	{name:'琼海市',py:'Q'},
	{name:'文昌市',py:'W'},
	{name:'万宁市',py:'W'},
	{name:'东方市',py:'D'},
	{name:'定安',py:'D'},
	{name:'屯昌',py:'T'},
	{name:'澄迈',py:'C'},
	{name:'临高',py:'L'},
	{name:'重庆市',py:'C'},
	{name:'成都市',py:'C'},
	{name:'自贡市',py:'Z'},
	{name:'攀枝花市',py:'P'},
	{name:'泸州市',py:'L'},
	{name:'德阳市',py:'D'},
	{name:'绵阳市',py:'J'},
	{name:'广元市',py:'G'},
	{name:'遂宁市',py:'S'},
	{name:'内江市',py:'N'},
	{name:'乐山市',py:'L'},
	{name:'南充市',py:'N'},
	{name:'眉山市',py:'M'},
	{name:'宜宾市',py:'Y'},
	{name:'广安市',py:'G'},
	{name:'达州市',py:'D'},
	{name:'雅安市',py:'Y'},
	{name:'巴中市',py:'B'},
	{name:'资阳市',py:'Z'},
	{name:'阿坝藏族羌族自治州',py:'A'},
	{name:'甘孜藏族自治州',py:'G'},
	{name:'凉山彝族自治州',py:'L'},
	{name:'白沙黎族自治县',py:'B'},
	{name:'昌江黎族自治县',py:'C'},
	{name:'乐东黎族自治县',py:'L'},
	{name:'陵水黎族自治县',py:'L'},
	{name:'保亭黎族苗族自治县',py:'B'},
	{name:'琼中黎族苗族自治县',py:'Q'},
	{name:'贵阳市',py:'G'},
	{name:'六盘水市',py:'L'},
	{name:'遵义市',py:'Z'},
	{name:'安顺市',py:'A'},
	{name:'毕节市',py:'B'},
	{name:'铜仁市',py:'T'},
	{name:'黔西南布依族苗族自治州',py:'Q'},
	{name:'黔东南苗族侗族自治州',py:'Q'},
	{name:'黔南布依族苗族自治州',py:'Q'},
	{name:'昆明市',py:'K'},
	{name:'曲靖市',py:'Q'},
	{name:'玉溪市',py:'Y'},
	{name:'保山市',py:'B'},
	{name:'昭通市',py:'Z'},
	{name:'丽江市',py:'L'},
	{name:'普洱市',py:'P'},
	{name:'临沧市',py:'L'},
	{name:'楚雄彝族自治州',py:'C'},
	{name:'红河哈尼族彝族自治州',py:'H'},
	{name:'文山壮族苗族自治州',py:'W'},
	{name:'西双版纳傣族自治州',py:'X'},
	{name:'大理白族自治州',py:'D'},
	{name:'德宏傣族景颇族自治州',py:'D'},
	{name:'怒江傈僳族自治州',py:'N'},
	{name:'迪庆藏族自治州',py:'D'},
	{name:'拉萨市',py:'L'},
	{name:'日喀则市',py:'R'},
	{name:'昌都市',py:'C'},
	{name:'林芝市',py:'L'},
	{name:'山南市',py:'S'},
	{name:'那曲地区',py:'N'},
	{name:'阿里地区',py:'A'},
	{name:'西安市',py:'X'},
	{name:'铜川市',py:'T'},
	{name:'宝鸡市',py:'B'},
	{name:'咸阳市',py:'X'},
	{name:'渭南市',py:'W'},
	{name:'延安市',py:'Y'},
	{name:'汉中市',py:'H'},
	{name:'榆林市',py:'Y'},
	{name:'安康市',py:'A'},
	{name:'商洛市',py:'S'},
	{name:'兰州市',py:'L'},
	{name:'嘉峪关市',py:'J'},
	{name:'金昌市',py:'J'},
	{name:'白银市',py:'B'},
	{name:'天水市',py:'T'},
	{name:'武威市',py:'W'},
	{name:'张掖市',py:'Z'},
	{name:'平凉市',py:'P'},
	{name:'酒泉市',py:'J'},
	{name:'庆阳市',py:'Q'},
	{name:'定西市',py:'D'},
	{name:'陇南市',py:'L'},
	{name:'临夏回族自治州',py:'L'},
	{name:'甘南藏族自治州',py:'G'},
	{name:'西宁市',py:'X'},
	{name:'海东市',py:'H'},
	{name:'海北藏族自治州',py:'H'},
	{name:'黄南藏族自治州',py:'H'},
	{name:'海南藏族自治州',py:'H'},
	{name:'果洛藏族自治州',py:'G'},
	{name:'玉树藏族自治州',py:'Y'},
	{name:'海西蒙古族藏族自治州',py:'H'},
	{name:'银川市',py:'Y'},
	{name:'石嘴山市',py:'S'},
	{name:'吴忠市',py:'W'},
	{name:'固原市',py:'G'},
	{name:'中卫市',py:'Z'},
	{name:'乌鲁木齐市',py:'W'},
	{name:'克拉玛依市',py:'K'},
	{name:'吐鲁番市',py:'T'},
	{name:'哈密市',py:'H'},
	{name:'昌吉回族自治州',py:'C'},
	{name:'博尔塔拉蒙古自治州',py:'B'},
	{name:'巴音郭楞蒙古自治州',py:'B'},
	{name:'阿克苏地区',py:'A'},
	{name:'克孜勒苏柯尔克孜自治州',py:'K'},
	{name:'喀什地区',py:'K'},
	{name:'和田地区',py:'H'},
	{name:'伊犁哈萨克自治州',py:'Y'},
	{name:'塔城地区',py:'T'},
	{name:'阿勒泰地区',py:'A'},
	{name:'石河子市',py:'S'},
	{name:'阿拉尔市',py:'A'},
	{name:'图木舒克市',py:'T'},
	{name:'五家渠市',py:'W'},
	{name:'北屯市',py:'B'},
	{name:'铁门关市',py:'T'},
	{name:'双河市',py:'S'},
	{name:'可克达拉市',py:'K'},
	{name:'昆玉市',py:'K'},
	{name:'台湾',py:'T'},
	{name:'香港',py:'X'},
	{name:'澳门',py:'A'}
]