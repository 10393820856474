<template>
  <div class="">
    <el-card class="box-card">
        <div class="sb-one">
          <el-select
              class="job-select"
              v-model="jobId"
              placeholder="全部职位"
              ref="jobSelector"
              title="匹配的职位:"
              @change="(val) => selectJob(val)">
            <el-option
                style="text-overflow: ellipsis;white-space: nowrap;"
                v-for="item2 in positionoptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
                :title="item2.label"
            >
              <span>{{ item2.label }}</span>
            </el-option>
          </el-select>
          <div class="search-item">
            <div class="city font16" @click="cityshow(pageInfo.city)">
              <span>{{ pageInfo.city ? pageInfo.city : "全国" }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-divider direction="vertical"></el-divider>

            <div class="inputmain">
              <el-input
                  v-model="keyword"
                  placeholder="请输入求职意向、项目经历搜索"
              ></el-input>
            </div>
            <div class="s-btn" @click="Search(true)">搜索</div>
          </div>
        </div>


      <div class="sb-two">
        <div class="st-left font14">
          <span>历史搜索：</span>
          <div
            class="history"
            @click="setKeywords(item)"
            v-for="(item, index1) in historylist"
            :key="index1"
          >
            {{ item }}
          </div>
        </div>
        <div class="st-right font18">
          <i class="el-icon-delete-solid" @click="clearSearch"></i>
        </div>
      </div>
      <!-- <div class="sb-three font16">
				<div class="quyu">区域</div>
				<div class="quyumain">
					<span v-for="(qu,index2) in 8" :key="index2">全杭州</span>
				</div>
			</div> -->
      <div class="sb-four font14">
        <div class="gaoji">高级搜索</div>
        <div class="zhankai" @click="zhanstuts = !zhanstuts">
          {{ !zhanstuts ? "展开" : "收起" }}
          <i :class="zhanstuts ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </div>
      </div>
    </el-card>
    <el-card class="box-card tiaojian" v-if="zhanstuts">
      <el-form
        :model="pageInfo"
        ref="postruleForm"
        label-position="top"
        label-width="130px"
        class="demo-ruleForm from-box"
      >
        <el-form-item label="项目周期" class="xmzhouqi">
          <el-date-picker
            type="date"
            placeholder="起"
            v-model="pageInfo.start"
            @change="dateStart"
            style="width: 182px; margin-right: 30px"
          ></el-date-picker>
          <el-date-picker
            type="date"
            placeholder="止"
            v-model="pageInfo.end"
            @change="dateEnd"
            style="width: 182px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="工作年限：" style="margin-left: 30px">
          <el-select
            v-model="pageInfo.expLow"
            placeholder="最低"
            @change="switchLow1"
            style="width: 162px; margin-right: 30px"
          >
            <el-option
              v-for="item in expLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="pageInfo.expHigh"
            placeholder="最高"
            style="width: 162px"
          >
            <el-option
              v-for="item in expHighOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="期望薪资">
          <el-select
            v-model="pageInfo.salaryLow"
            @change="switchLow"
            placeholder="不限"
            style="width: 182px; margin-right: 30px"
          >
            <el-option
              v-for="item in gzLowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            @change="change"
            v-model="pageInfo.salaryHigh"
            placeholder="不限"
            style="width: 182px"
          >
            <el-option
              v-for="item in gzHighOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" style="margin-left: 30px">
          <el-select
            v-model="pageInfo.educationTypes"

            placeholder="请选择学历"
            style="width: 350px"
          >
            <el-option
              v-for="item in educationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button class="chongzhi" @click="resetForm('postruleForm')"
            >重置</el-button
          >
          <el-button class="chongzhi" @click="Search(true)">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 列表 -->
    <el-card class="box-card listbox" style="margin-top: 15px">
      <!-- 无数据 -->
      <div class="nodata" v-if="userList != null && userList.length == 0">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font16" v-if="!loading">当前无数据</div>
      </div>
      <div class="">
        <el-card
          class="li-main"
          v-for="(itemes, index3) in userList"
          :key="index3"
        >
          <div class="list-content" @click="todetail(itemes)">
            <div :class="itemes.isTalk == 1 ? 'person-info1' : 'person-info'">
              <div class="p-picurl">
                <img
                  :src="'https://freemen.work/user/file/view/' + itemes.avatar"
                />
                <span
                  class="bofang font20"
                  @click.stop="videoshow(itemes)"
                  v-if="itemes.resUrl"
                >
                  <!-- <i class="el-icon-video-play"></i> -->
                  <img src="../../assets/images/common/bofangicon.png" />
                </span>
              </div>
              <div class="p-news">
                <div class="pn-name">
                  <div class="namemain">
                    <span class="n-name font22">{{ itemes.displayName }}</span>
                    <img
                      src="../../assets/images/person/boy.png"
                      v-if="itemes.sex == 1"
                    />
                    <img src="../../assets/images/person/girl.png" v-else />
                    <!-- <span class="font14">Free</span> -->
                  </div>
                  <div class="score font14">
                    <span>{{ itemes.score }}</span>
                  </div>
                </div>
                <div class="beizhu font14">
                  <div class="">
                    {{ itemes.expYear }}年 |
                    {{
                      itemes.educationList && itemes.educationList.length>0
                        ? itemes.educationList[itemes.educationList.length - 1].educationType
                          ? itemes.educationList[itemes.educationList.length - 1].educationType.itemText : "不限"
                        : "不限"
                    }}
                    |
                    {{
                      new Date().getYear() -
                      new Date(itemes.birthday).getYear() -
                      (new Date().getMonth() >
                      new Date(itemes.birthday).getMonth()
                        ? 0
                        : 1)
                    }}岁 | {{ itemes.jobLevelNames }}
                  </div>
                  <div class="">
                    期望工作时间：{{
                      itemes.startDate ? itemes.startDate.split(" ")[0] : ""
                    }}
                    —
                    {{ itemes.endDate ? itemes.endDate.split(" ")[0] : "/" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="transitionbox" v-if="itemes.userWorkExpList.length > 0">
              <!-- 3个 -->
              <template v-if="itemes.userWorkExpList.length < 4">
                <div
                  class="t-one font14"
                  v-for="(item, index) in itemes.userWorkExpList"
                  :key="index"
                >
                  <img src="../../assets/images/person/gangwei.png" />
                  <span @click.stop="getMoreWork(itemes)">
                    {{ item.companyName }} ·
                    {{ item.jobName }}
                  </span>
                </div>
              </template>
              <!-- 大于3 -->
              <template v-if="itemes.userWorkExpList.length > 3">
                <div class="t-one font14">
                  <img src="../../assets/images/person/gangwei.png" />
                  <span @click.stop="getMoreWork(itemes)">
                    {{ itemes.userWorkExpList[0].companyName }} ·
                    {{ itemes.userWorkExpList[0].jobName }}
                  </span>
                  <i
                    :class="
                      itemes.moreShow
                        ? 'el-icon-arrow-up'
                        : 'el-icon-arrow-down'
                    "
                    @click.stop="getMoreWork(itemes)"
                  ></i>
                </div>
              </template>
              <!-- 大于3 -->
              <el-collapse-transition v-if="itemes.userWorkExpList.length > 3">
                <div v-show="itemes.moreShow">
                  <div
                    class="t-one font14"
                    v-show="index > 0"
                    v-for="(item, index) in itemes.userWorkExpList"
                    :key="index"
                  >
                    <img src="../../assets/images/person/gangwei.png" />
                    <span>{{ item.companyName }} · {{ item.jobName }}</span>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="label-info">
              <div class="li-left font14">
                <span
                  v-show="index < 8"
                  v-for="(item, index) in itemes.skillIds"
                  :key="index"
                  >{{ item?item.itemText:"" }}</span
                >
              </div>
              <div class="li-right">
                <el-button class="gt" type="primary" @click.stop="Chat(itemes)"
                  >沟通</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
        <div class="nextpage" @click="getMoreList" v-if="moreBtn">
          点击加载更多
        </div>
      </div>
    </el-card>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;margin-top: 100px;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNo"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div> -->

    <!-- 沟通消耗聊天卡弹窗 -->
    <el-dialog
      :visible.sync="cardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起聊天将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="cardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="chatCommit"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
      :visible.sync="nocardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="nocardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="tipInfoVisible"
      width="600px"
      center
      :show-close="false"
      :close-on-click-modal="true"
      :modal-append-to-body="false"
    >
     <span class="closeicon" @click="switchTipVisible">
       <i class="el-icon-error"></i>
     </span>
      <div class="updown_box">
        {{this.tipInfoContent}}
      </div>
  </el-dialog>
    <!-- 城市选择 -->
    <choosecity></choosecity>
    <!-- 搜索的简历详情 -->
    <searchresumedetails></searchresumedetails>
    <!-- 视频详情 -->
    <videodetail></videodetail>
    <!-- 视频详情 -->
    <videodetail2></videodetail2>
    <!-- 证书图片 -->
    <imageChekcer></imageChekcer>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import { options } from "../../common/options.js";
import choosecity from "/src/components/person/choosecity.vue"; //举报
import searchresumedetails from "/src/components/person/searchresumedetails.vue"; //搜素的简历详情
import videodetail from "/src/components/person/videodetail.vue"; //视频详情
import videodetail2 from "/src/components/person/videodetail2.vue"; //视频详情
import imageChekcer from "./imageChekcer"; // 查看证书图片
export default {
  components: {
    choosecity,
    searchresumedetails,
    videodetail,
    videodetail2,
    imageChekcer,
  },
  data() {
    return {
      citynum: 1,
      zhanstuts: false,
      searcinput: "",
      historylist: localStorage.getItem("searchhistory")
        ? JSON.parse(localStorage.getItem("searchhistory"))
        : [],
      total: 0,
      jobId: "",
      jobName:"",
      // 全部状态
      positionoptions: [],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        city: "",
        start: "",
        end: "",
        expLow: "",
        expHigh: "",
        // educationTypes: [],
        educationTypes: '',
        salaryLow: "",
        salaryHigh: "",
        pageInfo: "",
      },
      postruleForm: {
        startTime: "",
        endTime: "",
        years: "",
        gzmin: "",
        gzmax: "",
        education: "",
      },
      educationOptions: JSON.parse(sessionStorage.getItem("educationType")),
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      expHighOptions: options.expHighOptions,
      expLowOptions: options.expLowOptions,
      gzoptions: [
        { value: "1000", label: "1000" },
        { value: "2000", label: "2000" },
      ],
      userList: [],
      cardDialogVisible: false, //消耗聊天卡弹窗
      nocardDialogVisible: false, //聊天卡额度不足弹窗
      tipInfoVisible: false,
      tipInfoContent:"",
      moreBtn: false,
      loading: false,
      keyword: "",
      chatConfig: {
        companyJobId: "",
        userId: "",
        userJobId: "",
      },
    };
  },
  activated() {
    this.getJobList();
  },
  created() {
    // console.log("Search--------",this.$route.query);

    setTimeout(() => {
      this.getLocation();
    }, 1000);
  },

  computed: {},
  methods: {
    setKeywords(k) {
      this.keyword = k;
      this.pageInfo.keyword = k;
      this.$forceUpdate();
      this.getJobList();
    },
    getLocation() {
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          // data是具体的定位信息
          this.pageInfo.city = data.addressComponent.city;
          // console.log("定位成功信息：", data);
        }
        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
      });
    },
    getLngLatLocation() {
      let that = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          // console.log("search person getLngLatLocation.result=", result)
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            // console.log("通过ip获取当前城市：", result);
            that.pageInfo.city = result.city;
            //逆向地理编码
            // AMap.plugin("AMap.Geocoder", function () {
            //   var geocoder = new AMap.Geocoder({
            //     // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            //     city: result.adcode,
            //   });

            //   var lnglat = result.rectangle.split(";")[0].split(",");
            //   geocoder.getAddress(lnglat, function (status, data) {
            //     if (status === "complete" && data.info === "OK") {
            //       // result为对应的地理位置详细信息
            //       // console.log(data);
            //     }
            //   });
            // });
          }
        });
      });
    },
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
    selectJob(val) {
      // console.log("$onchange ---- positionoptions = ", this.positionoptions);
      let label = this.positionoptions.map((v) => { return v.value === val ? v.label : null }).toString();
      this.jobName = label.replaceAll(",", "");
      label = "关联职位设置为 " + this.jobName;
      // console.log("$onSelected ----- companyJob ----- val = ", val, ", label = ",label);
      this.$message({
        message: label,
        type: "warning",
      });
      // this.pageInfo.pageNo = 1;
      // this.userList = [];
      // this.getList();
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          }
          if (this.positionoptions.length > 0) {
            this.jobId = this.positionoptions[0].value;
          }
          // console.log("SearchP getJobList.result = ", res, "---- this.positionoptions = ", this.positionoptions, "-----");
        });
        this.getList();
      });
    },
    switchLow(e) {
      let arr = [];
      this.gzHighOptions = options.gzHighOptions;
      this.pageInfo.salaryHigh = "";
      this.gzHighOptions.map((item) => {
        if (item.value > e) {
          arr.push(item);
        }
      });
      this.gzHighOptions = arr;
    },
    dateStart(){
      if(this.pageInfo.end&&Date.parse(this.pageInfo.start)>Date.parse(this.pageInfo.end)){
        this.pageInfo.start=this.pageInfo.end
      }
    },
    dateEnd(){
      if(this.pageInfo.start&&Date.parse(this.pageInfo.start)>Date.parse(this.pageInfo.end)){
        this.pageInfo.end=this.pageInfo.start
      }
    },
    switchLow1(e) {
      let arr = [];
      this.expHighOptions = options.expHighOptions;
      this.pageInfo.expHigh = "";
      this.expHighOptions.map((item) => {
        if (item.value > e) {
          arr.push(item);
        }
      });
      this.expHighOptions = arr;
    },
    getList() {
      if (
        this.keyword &&
        this.historylist.indexOf(this.keyword) == -1
      ) {
        this.historylist.push(this.keyword);
        window.localStorage.setItem(
          "searchhistory",
          JSON.stringify(this.historylist)
        );
      }
      let config = JSON.parse(JSON.stringify(this.pageInfo));
      if (this.pageInfo.start) {
        let startTime = new Date(config.start);
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        config.start = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.end) {
        let endTime = new Date(config.end);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        config.end = y + "-" + m + "-" + d;
      }
      // config.educationTypes = this.pageInfo.educationTypes.join(",");
      config.educationTypes = this.pageInfo.educationTypes;
      if (this.jobId) {
        config.companyJobId = this.jobId;
      }
      if (config.companyJobId == undefined) {
        return;
      }
      // console.log("$getLis t>>>>>>>>>>>>", config.companyJobId);
      this.$api.extList("get", config).then((res) => {
        // console.log(".$api.extList >>>>>>>>>>>>", res);
        if (this.pageInfo.pageNo == 1) {
          this.userList = res.data;
        } else {
          this.userList = this.userList.concat(res.data);
        }
        // this.userList = this.userList.concat(res.data);
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    Search(reset) {
      this.loading = true;
      if (reset) {
        this.userList = [];
        this.pageInfo.pageNo = 1;
      }
      if (this.keyword && this.historylist.indexOf(this.keyword) == -1) {
        this.historylist.push(this.keyword);
        window.localStorage.setItem(
          "searchhistory",
          JSON.stringify(this.historylist)
        );
      }
      if (this.keyword != null && this.keyword != "") {
        this.pageInfo.keyword = this.keyword;
      }
      let config = JSON.parse(JSON.stringify(this.pageInfo));
      if (this.pageInfo.start) {
        let startTime = new Date(config.start);
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        config.start = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.end) {
        let endTime = new Date(config.end);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        config.end = y + "-" + m + "-" + d;
      }
      // config.educationTypes = this.pageInfo.educationTypes.join(",");
      config.educationTypes = this.pageInfo.educationTypes;

      this.$api.searchPerson("get", config).then((res) => {
        // console.log(".$api.searchPerson >>>>>>>>>>>>", res);
        this.loading = false;

        this.userList = this.userList.concat(res.data);
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    getMoreList() {
      this.pageInfo.pageNo++;
      this.Search();
    },
    clearSearch() {
      this.historylist = [];
      localStorage.removeItem("searchhistory");
    },
    getMoreWork(data) {
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    getdata() {
      this.userList = [
        {
          id: 1,
          position: "安卓开发工程师",
          name: "张珊",
          Flag: false,
          show3: false,
        },
        {
          id: 2,
          position: "前端开发工程师",
          name: "李四",
          Flag: false,
          show3: false,
        },
        {
          id: 3,
          position: "后端开发工程师",
          name: "王五",
          Flag: false,
          show3: false,
        },
        {
          id: 4,
          position: "架构开发工程师",
          name: "孙六",
          Flag: false,
          show3: false,
        },
      ];
      // this.userListId = [1, 2, 3, 4]; //初始化多选选择框
    },
    // handleCurrentChange(val) {
    //   console.log(val);
    //   this.getdata(val)
    // },
    cityshow(city) {
      let config = {
        show: true,
        selectCity: city,
        callback: (val) => {
          this.pageInfo.city = val;
        },
      };
      Bus.$emit("sitystuts", config);
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      Bus.$emit("videodstuts", config);
    },
    todetail(data) {
      if (data.detail){
        this.jobId = data.companyJobId;
        let config = {
          show: true,
          data: data,
        };
        Bus.$emit("searchresumedstuts", config);
      } else {
        let config0 = {
          companyJobId: data.companyJobId,
          userJobId: data.userJobId,
        };
        this.$api.getCvdetail("get", config0).then((res) => {
          if(res.code === 10200){
            this.jobId = data.companyJobId;
            let config1 = {
              show: true,
              data: data,
            }
            Bus.$emit("searchresumedstuts", config1);
            setTimeout(() => {
              this.userList.map((userItem) => {
               if (userItem.companyJobId === data.companyJobId && userItem.userJobId === data.userJobId) {
                 userItem = res.data;
                 userItem.detail = true;
               }
              })
            }, 1000);
          } else {
            this.$message({
              message: "个人用户信息获取失败",
              type: "error",
            });
          }
        });
      }
    },
    resetForm(formName) {
      var self = this;
      self.$refs[formName].resetFields();
      self.pageInfo = {
        city: "",
        start: "",
        end: "",
        expLow: "",
        expHigh: "",
        educationTypes: [],
        salaryLow: "",
        salaryHigh: "",
        pageInfo: "",
        pageSize: 10,
      };
    },
    change(e) {
      if (e < this.pageInfo.salaryLow) {
        this.$message({
          message: "不能小于左边值",
          type: "error",
        });
        this.pageInfo.salaryHigh = "";
      }
    },
    Chat(data) {
      if(this.jobId){
        this.$api
            .checkChatCard("get", {
              // companyJobId: data.companyJobId,
              companyJobId: this.jobId,
              userJobId: data.userJobId,
            })
            .then((res) => {
              // debugger
              // console.log("Search person Chat------- api.checkChatCard.res = ", res);
              if(this.jobName === ""){
                let label = this.positionoptions.map((v) => { return v.value === this.jobId ? v.label : null }).toString();
                this.jobName = label.replaceAll(",", "");
              }
              if (res.data > 0) {
                let tempQuery = {
                  active: 1,
                  companyJobId: this.jobId,
                  companyJobName: this.jobName,
                  userJobId: data.userJobId,
                  userId: data.userId,
                }
                this.$router.push({ path: "chatmain", query: tempQuery});
                // this.$router.push("chatmain");
              } else {
                this.cardDialogVisible = true;
                // this.chatConfig.companyJobId = data.companyJobId;
                this.chatConfig.companyJobId = this.jobId;
                this.chatConfig.userId = data.userId;
                this.chatConfig.userJobId = data.userJobId;
              }
            });
      } else {
        // todo
        this.$message({
          message: "您尚未发布职位，当前无法匹配技术者！",
          type: "info",
        });
        // this.tipInfoVisible = true;
        // this.tipInfoContent = "您尚未发布职位，当前无法匹配技术者！";
      }
    },
    chatCommit() {
      this.$api.useChatCard("post", this.chatConfig).then((res) => {
        this.cardDialogVisible = false;
        if (res.code == "10901") {
          this.nocardDialogVisible = true;
        } else {
          let tempQuery = {
            active: 1,
            companyJobId: this.chatConfig.companyJobId,
            companyJobName: this.chatConfig.companyJobName,
            userJobId: this.chatConfig.userJobId,
            userId: this.chatConfig.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
          // this.$router.push("chatmain");
        }
      });
    },
    switchTipVisible(){
      this.tipInfoVisible = false;
    }
  },
};
</script>

<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: 3px;
  right: 8px;
  font-size: 26px;
  color: #000000;
}
.box-card {
  // background-color: #F0F9EB;
  border-radius: 0px;
  .sb-one {
    display: flex;
    align-items: center;
    height: 50px;
    //border: 1px solid #00bcff;
    .search-item {
      border: 1px solid rgb(0, 188, 255);
      display: flex;
      width: 92%;
      align-items: center;
    }
    .job-select {
      border: none;
      width: 8%;
      margin-right: 8px;
      margin-left: 8px;
    }
    .job-select /deep/ .el-input__inner {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .city:hover {
      cursor: pointer;
    }
    .city {
      width: 127px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;

      span {
        margin-right: 5px;
        color: #111111;
      }
    }
    .inputmain {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      /deep/ .el-input__inner {
        // border-radius: 0px;
        border: none;
      }
    }
    .s-btn:hover {
      cursor: pointer;
    }
    .s-btn {
      width: 90px;
      color: #ffffff;
      background-color: #00bcff;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .sb-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .st-left {
      display: flex;
      align-items: center;
      color: #666666;
      .history:hover {
        cursor: pointer;
      }
      .history:nth-of-type(1)::before {
        width: 0px;
      }
      .history:nth-of-type(1) {
        padding: 0 20px 0 0;
      }
      .history {
        padding: 0 20px;
        position: relative;
      }
      .history::before {
        position: absolute;
        left: 0;
        top: 25%;
        content: "";
        display: block;
        width: 0.5px;
        height: 10px;
        background-color: #666666;
      }
    }
    .st-right:hover {
      cursor: pointer;
    }
    .st-right {
      color: #666666;
    }
  }
  .sb-three {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .quyu {
      color: #ff8400;
    }
    .quyumain {
      display: flex;
      align-items: center;
      flex: 1;
      span:hover {
        cursor: pointer;
        color: #ff8400;
      }
      span {
        color: #111111;
        margin-left: 17px;
      }
    }
  }
  .sb-four {
    color: #666666;
    display: flex;
    align-items: center;
    margin-top: 30px;
    .gaoji {
      margin-right: 50px;
    }
    .zhankai:hover {
      cursor: pointer;
    }
    .zhankai {
      .el-icon-arrow-down {
        // margin-left: 3px;
      }
    }
  }
}

.tiaojian {
  margin-top: 15px;
  .from-box {
    width: 800px;
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    // /deep/.el-form-item:nth-child(even){
    // 	margin-left: 30px;
    // }
    /deep/.el-form--label-top,
    /deep/.el-form-item__label {
      padding: 0;
      color: #111111;
      font-size: 14px;
    }
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .chongzhi {
      width: 200px;
      background-color: #00bcff;
      border: none;
      color: #ffffff;
    }
  }
}
.listbox {
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
  }
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #111111;
    padding: 37px 0 20px 0;
    img {
      width: 256px;
      height: 215px;
      margin-bottom: 20px;
    }
  }
  .li-main:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .li-main {
    margin-bottom: 15px;
    .list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      .person-info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
            filter: blur(3px);
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
              filter: blur(0px);
            }
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .n-name {
                filter: blur(3px);
              }
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              span:last-child {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            div {
              margin-right: 48px;
            }
          }
        }
      }

      .person-info1 {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
            // filter: blur(3px);
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
              // filter: blur(0px);
            }
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              // .n-name {
              //   // filter: blur(3px);
              // }
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              span:last-child {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            div {
              margin-right: 48px;
            }
          }
        }
      }
      .transitionbox {
        .t-one {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #666666;
          margin-top: 20px;
          img {
            width: 22px;
            height: 20px;
          }
          span {
            margin: 0 30px 0 10px;
          }
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .li-left {
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin-right: 10px;
            color: #666666;
          }
        }
        .li-right {
          display: flex;
          align-items: center;
          /deep/ .el-button {
            border-radius: 0px;
            height: 40px;
            margin-left: 30px;
            border: none;
          }
          .gt {
            background-color: #00bcff;
          }
          .yy {
            background-color: #ffa544;
          }
        }
      }
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.updown_box {
  padding: 20px;
  text-align: center;
}
.updown_box .ub-img img {
  width: 50px;
}
.ub-text {
  margin: 20px 0 50px 0;
}
.updown_box .qx {
  border: 1px solid #00bcff;
  background-color: #ffffff;
  color: #00bcff;
  width: 150px;
  margin-right: 20px;
}
.updown_box .qr {
  background-color: #00bcff;
  border: 1px solid #00bcff;
  width: 150px;
  margin-left: 20px;
}
</style>
