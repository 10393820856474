<template>
  <div class="">
    <el-dialog :visible.sync="imageDialogVisible" width="600px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
      <div class="details">
        <div type="card" style="display: flex; align-items: center;justify-content: center;">
          <div v-for="(item, index) in urls" :key="index" v-show="!flag">
            <img style="height:200px" :src="baseUrl + item" >
          </div>
          <div v-show="flag">
            图片出了错误！
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from '../../common/bus.js';
export default {
  data() {
    return {
      imageDialogVisible: false,
      flag: false,
      urls:[],
      baseUrl:"",
    };
  },
  mounted() {
    Bus.$on('imageCheckerStatus', data => {
      if (this.baseUrl === ""){
        let tempUrl = window.location.origin;
        if(tempUrl.split(':').length > 2){
          this.baseUrl = tempUrl.split(':')[0]+":"+tempUrl.split(':')[1]+":8200/user/file/view/"
        } else {
          this.baseUrl =  tempUrl + "/user/file/view/";
        }
      }
      this.imageDialogVisible = data.show;
      this.urls = [];
      if(data.urls.split(",").length > 1){
        this.urls = data.urls.split(",");
      } else if(data.urls.split(",").length === 1) {
        this.urls.push(data.urls);
      } else {
        this.flag = true;
      }
    });
  },
  methods: {
    closebox() {
      this.imageDialogVisible = false;
    }
  }
}
</script>

<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.details {
  padding: 60px 40px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
