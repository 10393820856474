<template>
	<div class="">
		<el-dialog :visible.sync="dialogVisible" width="700px" :show-close="false" :modal-append-to-body="false" :lock-scroll="false">
			<div class="details">
				<span class="closeicon" @click="closebox()">
					<i class="el-icon-error"></i>
				</span>
				<div class="wb-tit font18">请选择城市</div>
				<div class="cityjz">
					<div :class="activenum==item.id?'activeclass':''" v-for="(item,index) in cityHeader" :key="index" @click="changecity(item)">
						<span>{{item.name}}</span>
					</div>
				</div>
				<div class="citybox">
					<div class="font14" :class="{'active':item.name == selectName}" v-for="(item,index2) in citymain" :key="index2" @click="selectCity(item)">
						{{item.name}}
					</div>
				</div>
				
				
				
				
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Bus from "../../common/bus.js"
import { city } from '../../common/city.js';
export default {
	data() {
		return {
			dialogVisible: false,
			cityHeader:[
				{name:'ABCD',id:1},
				{name:'EFJH',id:2},
				{name:'IGKL',id:3},
				{name:'MNOP',id:4},
				{name:'QRSTU',id:5},
				{name:'VWXYZ',id:6},
			],
			citymain:[],
			activenum:1,
			callback:null,
			selectName:'',
		};
	},
	mounted() {
		Bus.$on('sitystuts', (data) => {
			this.dialogVisible=data.show;
			this.callback = data.callback
			this.selectName = data.selectCity
			if(data.selectCity){
				let py = ''
				city.map(item=>{
					if(item.name == data.selectCity){
						py = item.py
					}
				})
				this.cityHeader.map((item,index)=>{
					if(item.name.indexOf(py) != -1){
						this.changecity(this.cityHeader[index])
					}
				})
			}else{
				this.changecity(this.cityHeader[0])
			}
		})
	},
	methods: {
		closebox(){
			this.dialogVisible=false;
			Bus.$emit('sitystuts', false)
		},
		changecity(data){
			this.activenum=data.id;
			let arr = []
			city.map(item=>{
				if(data.name.indexOf(item.py) != -1){
					arr.push(item)
				}
			})
			this.citymain = arr
		},
		selectCity(data){
			this.selectName = data.name
			this.callback(data.name)
			this.closebox()
		}
	}
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon{
		position: absolute;
		top:-40px;
		right: 0;
		font-size:26px;
		color: #F3F3F3;
	}
.details{
	.wb-tit{
			padding: 17px 0 17px 30px;
			text-align: left;
			background-color: #FFFFFF;
			color: #000000;
			
		}
	.cityjz{
		background-color: #F3F3F3;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			height: 50px;
		}
		.activeclass{
			color: #00BCFF;
			span{
				height: 95%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 2px solid #00BCFF;
			}
			
			
		}
	}
	.citybox{
		display: flex;
		flex-wrap: wrap;
		padding: 30px 0 100px 0;
		div{
			width: 140px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #111111;
		}
		.active{
			color: #00BCFF;
		}
	}
	
}
	
	
	
	
	
	
	


</style>
